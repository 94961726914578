import React, { memo } from 'react'
import { useMainContext } from 'components/molecules/main-context'

import { Container, Logos, Stars, Title } from './ratings-module.styles'

import { TRatingsModuleProps, Variant, defaultProps } from '.'

const RatingsModule: React.FC<TRatingsModuleProps> = ({
  variant = defaultProps.variant,
  title = defaultProps.title,
  logos,
  ...rest
}) => {
  const { t } = useMainContext()

  const defaultTitle =
    variant === Variant.Stars
      ? t('ratings-module.tagline.link-text')
      : t('ratings-module.partners.title')

  return (
    <Container data-qa='ratings-module' {...rest}>
      {variant === Variant.Stars && <Stars />}
      <Title dangerouslySetInnerHTML={{ __html: title || defaultTitle }} />
      {variant === Variant.Partners && <Logos logos={logos} />}
    </Container>
  )
}

export default memo(RatingsModule)
